<script>
import Layout from "../../layouts/main";
import { mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable'
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core';
import { uuid } from 'vue-uuid';
import UserGroupSelection from '@/components/user-group-selection'
import { javascript } from '@codemirror/lang-javascript'


export default {
  setup () {
    const extensions = [javascript()]
    return { v$: useVuelidate(), extensions }
  },
  components: {
    Layout,
    draggable,
    UserGroupSelection
  },
  props:{
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      initializing: false,
      processing: false,
      loading: false,
      inited: false,
      statusFormVisible: false,
      status: null,

      editFieldItem: null,
      directories:[],
      
      item: {
        fields: [],
        visibleFor: []
      }
    };
  },
  validations() {

    const rules = {
      item: {
        name: { required }
      }
    };

    return rules;
  },
  created() {
      this.directories = this.directoriesFromState;
    document.title = this.pagetitle;
    this.init();
  },
  computed :{
    isNew() {
      return this.id.toLocaleLowerCase() === 'new' ? true: false;
    },
    pagetitle () {
      return this.isNew ? this.$t("usageProfile.editFormNewItemTitle") : this.$t("usageProfile.editFormEditItemTitle");
    },  
    ...mapGetters('boot', {
      appReady: 'ready'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('directories', {
      directoriesFromState: 'items'
    }),
    ...mapGetters('boards', {
      boards: 'items'
    }),
    ...mapGetters('usageProfiles', {
      usageProfiles: 'items',
      deleting: 'deleting'
    }),
    ...mapGetters('userGroups', {
      everyone: 'everyone',
      userGroups: 'items'
    }),
    ...mapGetters('users', {
      users: 'items'
    }),
     ...mapGetters('boards', {
      boards: 'items'
    }),
    availableDirectories() {
      return this.directories.filter(dir => !this.item.directoryAccess.find(a => a.directory.id == dir.id));
    },
    availableDirectoryMenuItems() {
      const o = this.item.directoryAccess.map(x => x.directory);
      return o.filter(dir => !this.item.menu.find(a => a.target.id === dir.id));
    },
    availableReportMenuItems() {
      let res = [];
      if(!this.item.menu.find(a => a.target === 'process-report-1')) {
        res.push({ type: 'Report', target: 'process-report-1', title: 'Кількість наданої допомоги'});
      }
      if(!this.item.menu.find(a => a.target === 'request-report-1')) {
        res.push({ type: 'Report', target: 'request-report-1', title: 'Кількість бажаної допомоги'});
      }
      if(!this.item.menu.find(a => a.target === 'heat-map-1')) {
        res.push({ type: 'Report', target: 'heat-map-1', title: 'Теплова мапа'});
      }
      return res;
    },
    availableBoardMenuItems() {
      const o = this.boards;
      return o.filter(board => !this.item.menu.find(a => a.target.id === board.id));
    }
  },
  watch: {
    appReady (newValue, oldValue) {
      if(newValue !== oldValue) {
        this.init();
      }
    }
  },
  methods: {
    ...mapActions('usageProfiles', {
      create: 'create',
      update: 'update'
    }),
    populate(source) {
      const o = {};
      source = JSON.parse(JSON.stringify(source));
      o.id = source.id;
      o.name = source.name;

      o.assignment = source.assignment
        .filter(x => x.type === 'UserGroup' && (this.users.find(u => u.id === x.target) || this.userGroups.find(u => u.id === x.target)))
        .map(x => this.userGroups.find(u => u.id === x.target));

      const directoryAccess = source.directoryAccess.filter(x => this.directories.find(u => u.id === x.directoryId));
      directoryAccess.forEach(x => {
          const dir = this.directories.find(u => u.id === x.directoryId);
          x.hiddenFields = x.hiddenFields.filter(fieldId => dir.fields.find(a => a.id === fieldId));
          x.hiddenFields = x.hiddenFields.map(id => dir.fields.find(a => a.id === id));
        });
      
      o.directoryAccess = directoryAccess.map(x => ({...x, directory: this.directories.find(u => u.id === x.directoryId)}));
      const menu = [];
      source.menu.forEach(x => {
        let target = null;
        if(x.type === 'Map') {
          target = 'map';
        } else if(x.type === 'Report') {
          target = x.target;
        } else if(x.type === 'Directory') {
          target = this.directories.find(u => u.id === x.target);
        } else  { 
          target = this.boards.find(u => u.id === x.target);
        }
        const i = {
          type: x.type,
          target: target
        };
        if(i.target) {
           menu.push(i);
        }
      });
      o.menu = menu;
      this.item = o;
    },
    createDto() {

      let dto = {
        id: this.item.id || uuid.v1(),
        name: this.item.name,
        assignment: [...this.item.assignment.map(x => ({ type: 'UserGroup', target: x.id}))],
        directoryAccess: [...this.item.directoryAccess.map(x => (
          {
            ...x, 
            directoryId: x.directory.id, 
            directory: undefined,
            hiddenFields: x.hiddenFields.map(a => a.id)
          }))],
        menu: this.item.menu.map(x => ({
          type: x.type,
          title: x.title,
          target:  x.type === 'Report' ? x.target : (x.type === 'Map' ? 'map' : x.target.id)
        }))
      };
      return dto;
    },
    async init() {
      if(!this.appReady) return;

      if(this.initializing || this.inited) {
        return;
      }
    
      this.initializing = true;
      if(this.isNew) {
        this.populate({
          assignment: [],
          menu: [],
          directoryAccess: []
        });
      } else {
        this.populate(this.usageProfiles.find(x => x.id === this.id));
      }
      this.inited = true;
      this.initializing = false;
    },
    async submit () {
      let isValid = await this.v$.$validate();
      this.errors = [];
    
      if(isValid) {
        
        const dto = this.createDto();
  
        let req = this.isNew ? this.create({ dto }) : this.update({ id: this.id, dto: dto});
        req.then(() => {
          this.$toast.open({message: this.$t('common.savedText')});
          this.$router.push({name: 'usage-profiles'});
        })
        .catch(error => {
          this.errors = error;
        });
      }
    },
    addReportMenuItem(report) {
      this.item.menu = [...this.item.menu, {
        type: 'Report',
        target: report.target,
        title: report.title
      }];
    },
    addDirMenuItem(dir) {
      this.item.menu = [...this.item.menu, {
        type: 'Directory',
        target: dir
      }];
    },
    addBoardMenuItem(board) {
       this.item.menu = [...this.item.menu, {
          type: 'Board',
          target: board
        }];
    },
    addMapMenuItem() {
       this.item.menu = [...this.item.menu, {
          type: 'Map',
          target: 'map'
        }];
    },
    removeMenuItem(item) {
       this.item.menu = this.item.menu.filter(x => item !== x);
    },
    addHiddenField(accessItem, field) {
      const o = [...accessItem.hiddenFields, field];
      o.sort((a, b) => a.name.localeCompare(b.name));
      accessItem.hiddenFields = o;
    },
    removeHiddenField(accessItem, field) {
      accessItem.hiddenFields = accessItem.hiddenFields.filter(x => x !== field);
    },
    addDirAccess(dir) {
      this.item.directoryAccess = [...this.item.directoryAccess, { 
        directory: dir, 

        allowRead: true,
        allowCreate: true,
        allowUpdate: true,
        allowDelete: true,

        allowAddNote: true,
        allowUpdateNote: true,
        allowReadNote: true,
        allowDeleteNote: true,

        allowAddFile: true,
        allowReadFile: true,
        allowDeleteFile: true,

        allowReadChanges: true,

        hiddenFields: []
      }];
    },
    removeDirAccess(rec) {
      this.item.directoryAccess = this.item.directoryAccess.filter(x => x !== rec);
      this.item.menu = this.item.menu.filter(x => x.type !== 'Directory' || x.target.id !== rec.directory.id);
    },
    getAvailableHiddenFields(accessItem){
      const fields = accessItem.directory.fields;
      return fields.filter(a => !accessItem.hiddenFields.find(c => c.id === a.id));
    }
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle + ( item.name ? (' [' + item.name + ']') : '')" :is-busy="initializing || loading || processing">
   
    <div v-if="inited" class="card mb-4" style="padding-bottom: 40px">
      <div class="card-body">

        <div class="alert mt-4 mb-4 alert-dismissible alert-danger" v-if="errors.length > 0">
          <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
          <ul class="list-unstyled">
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </div>
        <div>
          <div class="row gy-2 gx-3 align-items-center mb-3">
            <div class="col-md-12">
              <label  for="name">{{$t('usageProfile.fields.name')}}</label>
              <input type="text" class="form-control" id="name" :placeholder="$t('usageProfile.fields.name')" :class="{ 'is-invalid': v$.item.name.$error }" v-model="item.name"/>
              <div class="invalid-feedback">
                {{ $t("common.pleaseEnter") }} {{$t('usageProfile.fields.name')}}
              </div> 
            </div>
          </div>
          <div class="row gy-2 gx-3 align-items-center mb-3">
            <div class="col-md-12">
              <label  for="name">{{$t('usageProfile.fields.assignment')}}</label>
              <div>
                <user-group-selection 
                  :value="item.assignment" 
                  @update="v => item.assignment = v"
                >
                </user-group-selection>
              </div>
              
            </div>
          </div>
        </div>  
        <ul class="nav nav-tabs mt-4" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              data-bs-toggle="tab"
              href="#access"
              role="tab"
            >
              <span>{{$t('usageProfile.directoryAccessSectionTitle')}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-bs-toggle="tab"
              href="#menu"
              role="tab"
            >
              <span>{{$t('usageProfile.menuSectionTitle')}}</span>
            </a>
          </li>
        </ul>  
        <div class="tab-content py-3 text-muted">
          <div class="tab-pane active " id="access" role="tabpanel">
            <div class="d-flex flex-wrap mb-2">
              <div class="">
                <div class="dropdown">
                  <a
                    class="btn btn-light btn-sm"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                  >
                   <font-awesome-icon icon="fa-solid fa-plus" />
                    <div class="text-sm-end d-none d-md-inline-block ms-2">
                      {{ $t("usageProfile.addDirectory") }}
                    </div>
                  </a>

                  <div class="dropdown-menu">
                    <a
                      v-if="availableDirectories.length === 0" 
                      class="dropdown-item" href="javascript:void(0)" 
                    >
                      <span class="text-muted"> {{ $t("common.noRecordsFound") }}</span>
                    </a>
                    <a 
                      class="dropdown-item" href="javascript:void(0)" 
                      v-for="dir in availableDirectories" 
                      :key="dir.id"
                      @click="addDirAccess(dir)"
                    >
                      <span> {{dir.name}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <draggable
              tag="ul"
              v-model="item.directoryAccess" 
              group="fields" 
              @start="drag=true" 
              @end="drag=false" 
              handle=".drag-area"
              class="list-group"
              item-key="index"
            >
              <template #item="{element}">
              <li class="list-group-item">
                <div class="row bg-light p-2">
                  <div class="col">
                    <h5 class="drag-area mb-0" style="line-height: unset !important;">
                      <font-awesome-icon icon="fa-solid fa-grip-vertical"  class="drag-area me-2 text-muted"/>
                      {{element.directory.name}}
                    </h5>
                  </div>
                  <div class="col-auto">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      @click="removeDirAccess(element)"
                      class="btn btn-light btn-sm text-danger" 
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can" />
                      <div class="text-sm-end d-none d-md-inline-block ms-2">
                        {{ $t("usageProfile.removeDirectory") }}
                      </div>
                    </a>
                  </div>
                </div>
                
                <div>
                   
                  <table class="table table-borderless table-sm">
                   
                    <tbody>
                      <tr>
                        <th style="width:200px; max-width: 200px;">

                        </th>
                        <th>
                          {{$t('usageProfile.access.record')}}
                        </th>
                        <th>
                          {{$t('usageProfile.access.comments')}}
                        </th>
                        <th>
                          {{$t('usageProfile.access.files')}}
                        </th>
                         <th>
                          {{$t('usageProfile.access.changes')}}
                        </th>
                      </tr>
                      <tr>
                        <th style="width:200px; max-width: 200px;">{{$t('usageProfile.access.allowRead')}}</th>
                        <td  class="text-center">
                          <!-- <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowRead" v-model="element.allowRead"/>
                            <label class="form-check-label" for="allowRead"></label>
                          </div> -->
                        </td>
                        <td  class="text-center">
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowReadNote" v-model="element.allowReadNote"/>
                            <label class="form-check-label" for="allowReadNote"></label>
                          </div>
                        </td>
                        <td  class="text-center">
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowReadFile" v-model="element.allowReadFile"/>
                            <label class="form-check-label" for="allowReadFile"></label>
                          </div>
                        </td>
                        <td  class="text-center">
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowReadChanges" v-model="element.allowReadChanges"/>
                            <label class="form-check-label" for="allowReadChanges"></label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th style="width:200px; max-width: 200px;">{{$t('usageProfile.access.allowCreate')}}</th>
                        <td class="text-center">
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowCreate" v-model="element.allowCreate"/>
                            <label class="form-check-label" for="allowCreate"></label>
                          </div>
                        </td>
                        <td  class="text-center">
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowAddNote" v-model="element.allowAddNote"/>
                            <label class="form-check-label" for="allowAddNote"></label>
                          </div>
                        </td>
                        <td>
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowAddFile" v-model="element.allowAddFile"/>
                            <label class="form-check-label" for="allowAddFile"></label>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <th style="width:200px; max-width: 200px;">{{$t('usageProfile.access.allowUpdate')}}</th>
                        <td  class="text-center">
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowUpdate" v-model="element.allowUpdate"/>
                            <label class="form-check-label" for="allowUpdate"></label>
                          </div>
                        </td>
                        <td  class="text-center">
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowUpdateNote" v-model="element.allowUpdateNote"/>
                            <label class="form-check-label" for="allowUpdateNote"></label>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th style="width:200px; max-width: 200px;">{{$t('usageProfile.access.allowDelete')}}</th>
                        <td  class="text-center">
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowDelete" v-model="element.allowDelete"/>
                            <label class="form-check-label" for="allowDelete"></label>
                          </div>
                        </td>
                        <td  class="text-center">
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowDeleteNote" v-model="element.allowDeleteNote"/>
                            <label class="form-check-label" for="allowDeleteNote"></label>
                          </div>
                        </td>
                        <td  class="text-center">
                          <div class="form-check form-switch form-switch-sm mb-1">
                            <input class="form-check-input" type="checkbox" id="allowDeleteFile" v-model="element.allowDeleteFile"/>
                            <label class="form-check-label" for="allowDeleteFile"></label>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <th style="width:200px; max-width: 200px;">{{$t('usageProfile.access.hiddenFields')}}</th>
                        <td colspan="100">
                          <span style="position: relative; padding-right: 30px;" class="btn btn-sm border me-2 mb-1" 
                            v-for="(hf, index) in element.hiddenFields"
                            :key="index"
                          >
                            {{hf.name}}
                            <a
                              href="javascript:void(0)" 
                              style="position: absolute; right:5px"
                              @click="removeHiddenField(element, hf)"
                            >
                              <font-awesome-icon icon="fa-solid fa-xmark" />
                            </a>
                          </span>
                          <div class="dropdown" style="display: inline-block;">
                            <a
                              class="btn btn-sm btn-light"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                            >
                              <font-awesome-icon icon="fa-solid fa-plus" />
                              <div class="text-sm-end d-none d-md-inline-block ms-2">
                                {{ $t("usageProfile.addHiddenField") }}
                              </div>
                            </a>
                            
                            <div class="dropdown-menu">
                              <a
                                v-if="getAvailableHiddenFields(element).length === 0" 
                                class="dropdown-item" href="javascript:void(0)" 
                              >
                                <span class="text-muted"> {{ $t("common.noRecordsFound") }}</span>
                              </a>
                              <a 
                                class="dropdown-item" href="javascript:void(0)" 
                                v-for="field in getAvailableHiddenFields(element)" 
                                :key="field.id"
                                @click="addHiddenField(element, field)"
                              >
                                <span> {{field.name}}</span>
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              </template> 
            </draggable>
          </div>
          <div class="tab-pane " id="menu" role="tabpanel">
            <div class="d-flex flex-wrap mb-2">
              <div class="">
                <div class="dropdown d-inline-block text-nowrap me-2">
                  <a
                    class="btn btn-sm btn-light"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                  >
                   <font-awesome-icon icon="fa-solid fa-plus" />
                    <span class="text-sm-end ms-2">
                      {{ $t("usageProfile.addDirectory") }}
                    </span>
                  </a>

                  <div class="dropdown-menu">
                    <a
                      v-if="availableDirectoryMenuItems.length === 0" 
                      class="dropdown-item" href="javascript:void(0)" 
                    >
                      <span class="text-muted"> {{ $t("common.noRecordsFound") }}</span>
                    </a>
                    <a 
                      class="dropdown-item" href="javascript:void(0)" 
                      v-for="dir in availableDirectoryMenuItems" 
                      :key="dir"
                      @click="addDirMenuItem(dir)"
                    >
                      <span> {{dir.name}}</span>
                    </a>
                  </div>
                </div>
                <div class="dropdown d-inline-block text-nowrap me-2">
                  <a
                    class="btn btn-sm btn-light"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                  >
                   <font-awesome-icon icon="fa-solid fa-plus" />
                    <span class="text-sm-end ms-2">
                      {{ $t("usageProfile.addBoard") }}
                    </span>
                  </a>

                  <div class="dropdown-menu">
                    <a
                      v-if="availableBoardMenuItems.length === 0" 
                      class="dropdown-item" href="javascript:void(0)" 
                    >
                      <span class="text-muted"> {{ $t("common.noRecordsFound") }}</span>
                    </a>
                    <a 
                      class="dropdown-item" href="javascript:void(0)" 
                      v-for="board in availableBoardMenuItems" 
                      :key="board"
                      @click="addBoardMenuItem(board)"
                    >
                      <span> {{board.name}}</span>
                    </a>
                  </div>
                  
                </div>
                <div class="dropdown d-inline-block text-nowrap me-2">
                  <a
                    class="btn btn-sm btn-light"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                  >
                   <font-awesome-icon icon="fa-solid fa-plus" />
                    <span class="text-sm-end ms-2">
                      Додати отчет
                    </span>
                  </a>

                  <div class="dropdown-menu">
                    <a
                      v-if="availableReportMenuItems.length === 0" 
                      class="dropdown-item" href="javascript:void(0)" 
                    >
                      <span class="text-muted"> {{ $t("common.noRecordsFound") }}</span>
                    </a>
                    <a 
                      class="dropdown-item" href="javascript:void(0)" 
                      v-for="report in availableReportMenuItems" 
                      :key="report"
                      @click="addReportMenuItem(report)"
                    >
                      <span> {{report.title}}</span>
                    </a>
                  </div>
                  
                </div>
                <a
                  class="btn btn-sm btn-light d-inline-block text-nowrap"
                  @click="addMapMenuItem"
                  v-if="!this.item.menu.find(x => x.type === 'Map')"
                >
                  <font-awesome-icon icon="fa-solid fa-plus" />
                  <span class="text-sm-end ms-2">
                    {{ $t("usageProfile.addMapMenuItem") }}
                  </span>
                </a>
              </div>
            </div>
            <div class="table-responsive bg-light p-2 mt-2">
              <table
                class="table project-list-table table-nowrap align-middle table-borderless"
                style=""
              >
                <tbody  v-if="item.menu.length === 0">
                  <tr>
                    <td colspan="100">
                      <em class="text-muted">
                        {{ $t("common.noRecordsFound") }}
                      </em>
                    </td>
                  </tr>
                </tbody>
                <draggable
                  tag="tbody"
                  v-model="item.menu" 
                  group="menu" 
                  @start="drag=true" 
                  @end="drag=false" 
                  handle=".drag-area"
                  item-key="index"
                >
                  <template #item="{element}">
                    <tr>
                      <td class="drag-area"  style="max-width:40px; width:40px;">
                        <a href="javascript:void(0)" >
                          <i class="mdi mdi-drag font-size-18"></i>
                        </a>
                      </td>
                      <td>
                        <span v-if="element.type === 'Board'">{{element.target.name}}</span>
                        <span v-if="element.type === 'Map'">{{ $t("map.title") }}</span>
                        <span v-if="element.type === 'Directory'">{{element.target.name}}</span>
                        <span v-if="element.type === 'Report' && element.target === 'process-report-1'">Кількість наданої допомоги</span>
                        <span v-if="element.type === 'Report' && element.target === 'request-report-1'">Кількість бажаної допомоги</span>
                        <span v-if="element.type === 'Report' && element.target === 'heat-map-1'">Теплова мапа</span>
                      </td>
                      <td class="text-end" style="max-width:130px; width:130px;">
                        <ul class="list-inline mb-0">
                          
                          <li class="list-inline-item" >
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              @click="removeMenuItem(element)"
                              class="btn btn-light btn-sm text-danger" 
                            >
                              <font-awesome-icon icon="fa-solid fa-trash-can" />
                              <div class="text-sm-end d-none d-md-inline-block ms-2">
                                {{ $t("usageProfile.removeMenuItem") }}
                              </div>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </template>
                </draggable>
              </table>
            </div>
          </div>
        </div>    
      </div>
    </div>
    <div class="card card-body m-0 footer-page-actions" style="">
      <div class="row gy-2 gx-3 align-items-center  ">
        <div class="col">
          <router-link 
            to="/usage-profiles"
            class="btn btn-secondary"
          >
            {{$t('common.closeButtonTitle')}}
          </router-link>
        </div>
        <div class="col text-end">
          <button type="button" 
            class="btn btn-primary"
            :disabled="processing || loading" 
            @click="submit"
          >
            <i class="fa fa-spin fa-spinner me-2" v-if="processing"/>
            <i class="fa fa-save me-2" v-if="!processing"/>
            {{$t('common.saveButtonTitle')}}
          </button>
        </div>
      </div>
    </div>
  </Layout>
</template>
