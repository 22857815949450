<script>

import { mapGetters } from 'vuex';


export default {
  components: {  

  },
  props: {
    value: {
      type: Array,
      required: false,
      default: ()=> []
    }
  },
  data() {
    return {
      groupName: '',
      isAdding: false,
      editItemId: null
    };
  },
  computed: {
    ...mapGetters('userGroups', {
      groups: 'items'
    }),
    
    filterItems(){
      return this.groupName ? this.groups.filter(x => (x.name.includes(this.groupName) || x.email.includes(this.groupName))) : this.groups;
    }
  },
  methods:{
    add(){
      this.groupName = null;
      this.isAdding = true;
      this.$nextTick(()=> this.$refs.input.focus());
    },
    addGroup(group){
      const ids = this.value.map(x => x.id);
      const set = [...new Set([...ids, group.id])];
      const groups = set.map(x => this.groups.find(a => a.id === x)).filter(x => x);
      this.$emit("update", groups);
      this.end();
    },
    end() {
      this.groupName = null;
      this.isAdding = false;
    },
    remove(group){
      const ids = this.value.filter(x => x.id !== group.id).map(x => x.id);
      const set = [...new Set([...ids])];
      const groups = set.map(x => this.groups.find(a => a.id === x)).filter(x => x);
      this.$emit("update", groups);
    }
  }
};
</script>

<template>
  <span>
    <span>
      <span class="me-2 form-control me-2 mb-1 bg-light" 
        style="display:inline-block; width:auto" 
        
        v-for="(t, $index) in value" :key="$index"
      >
        
        <span>
          <span v-if="t.everyone">
            {{$t('userGroups.everyOneGroupName')}}
          </span>
          <span v-if="!t.everyone">
          {{t.name}}
          </span>
        </span>
        <small class="ms-2">
          <a href="javascript:void(0)" @click="remove(t)">&#10006;</a>
        </small>

      </span>
    </span>
    
    <a href="javascript:void(0)" 
      class="btn btn-outline-light waves-effect "
      v-if="!isAdding "
      @click="add"
    >
      <i class="fas fa-plus me-1"></i> Add
    </a>
    <div class="form-control " style="display:inline-block; min-width:150px;width:150px;position:relative; padding:0; z-index:10"
      v-click-outside="end"  
      v-if="isAdding"
     >
      <input 
        class="form-control"
        style="border:0;"
        v-model="groupName" 
        ref="input"
      />
      <div style="position: absolute;  left:-1px; min-width:150px; min-height:100px; overflow-y:auto; ">
        <div class="list-group"  >
          <template
            v-for="(group, $index) in filterItems" 
            :key="$index"
          >
            <a href="javascript:void(0)" 
              class="list-group-item text-nowrap"
              style="max-width:250px;text-overflow: ellipsis;overflow: hidden; " 
              v-if="!value.find(x => x.id === group.id)"
              @click.prevent="addGroup(group)"
            >
              <span v-if="group.everyone">
                {{$t('userGroups.everyOneGroupName')}}
              </span>
              <span v-if="!group.everyone">
              {{group.name}}
              </span>
            </a>
            
          </template>
        </div>
      </div>
    </div>
   
  </span>
</template>
